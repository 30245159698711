// @file Commands store
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useCommandsStore = defineStore('commands', () => {
  const xCommandDialog = ref(false)

  const showCommandDialog = (): void => {
    xCommandDialog.value = true
  }

  const closeCommandDialog = (): void => {
    xCommandDialog.value = false
  }

  return {
    // State
    xCommandDialog,

    // Actions
    showCommandDialog,
    closeCommandDialog,
  }
})
